<!--
 * @Author: your name
 * @Date: 2021-11-30 10:27:55
 * @LastEditTime: 2022-01-04 17:47:31
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sproutedus\src\views\product\index.vue
-->
<template>
  <div id="product">
    <!-- 内容 -->
    <div class="client">
      <div class="clientHeader">
        <div
          class="clientHeader-child"
          :class="productIndex==0?'active':''"
          @click="changeSection(0)"
        >
          <div
            class="childImg"
            :style="{backgroundImage:'url('+(productIndex==0?require('../../assets/icon_phone2.png'):require('../../assets/icon_phone.png'))+')'}"
          ></div>
          <span>移动APP应用</span>
        </div>
        <div
          class="clientHeader-child"
          :class="productIndex==3?'active':''"
          @click="changeSection(3)"
        >
          <div
            class="childImg"
            :style="{backgroundImage:'url('+(productIndex==3?require('../../assets/icon_pads2.png'):require('../../assets/icon_pads.png'))+')'}"
          ></div>
          <span>PAD端应用</span>
        </div>
        <div
          class="clientHeader-child"
          :class="productIndex==1?'active':''"
          @click="changeSection(1)"
        >
          <div
            class="childImg"
            :style="{backgroundImage:'url('+(productIndex==1?require('../../assets/icon_pad2.png'):require('../../assets/icon_pad.png'))+')'}"
          ></div>
          <span>智能终端应用</span>
        </div>
        <div
          class="clientHeader-child"
          :class="productIndex==2?'active':''"
          @click="changeSection(2)"
        >
          <div
            class="childImg"
            :style="{backgroundImage:'url('+(productIndex==2?require('../../assets/icon_TV2.png'):require('../../assets/icon_TV.png'))+')'}"
          ></div>
          <!-- <img class="img1" src="../../assets/icon_TV.png" alt="电视" v-if="productIndex!=2">
          <img class="img2" src="../../assets/icon_TV2.png" alt="电视" v-else>-->
          <span>电视端应用</span>
        </div>
      </div>
      <div class="moduleContain">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import AOS from "aos";
export default {
    name:'product',
    data(){
        return{
            productIndex:0,
        }
    },
    mounted(){
        let routePath = this.$route.path;
        if(routePath.indexOf('page1')>-1){
            this.productIndex=0
        }
        else if(routePath.indexOf('page2')>-1){
            this.productIndex=1
        }
        else if(routePath.indexOf('page3')>-1){
            this.productIndex=2
        }
        else{
            this.productIndex=3
        }
        AOS.init({
            offset: 200,   
            duration: 600,   
            easing: 'ease-in-sine',   
            delay: 100
        })
    },
    methods:{
        changeSection(n){
            if(n==this.productIndex) return false;
            let path = '/product'
            switch(n){
                case 0:
                    path += '/page1'
                    break;
                case 1:
                    path += '/page2'
                    break;
                case 2:
                    path += '/page3'
                    break;    
                case 3:
                    path += '/page4'
                    break    
                default:
                    break;
            }
            this.productIndex = n
            this.$router.push({path:path})
        }
    }
}
</script>
<style lang="scss">
@import "../../../static/common.scss";
.client {
  width: 100%;
  &Header {
    width: 100%;
    height: 80px;
    @include display(center, center);
    &-child {
      @include display(center, center);
      width: 200px;
      height: 100%;
      margin-right: 160px;
      cursor: pointer;
      .childImg {
        width: 22px;
        height: 22px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      img {
        width: 22px;
        height: 22px;
      }
      span {
        font-size: 18px;
        font-weight: 400;
        line-height: 31px;
        color: #a5a5a5;
        margin-left: 6px;
      }
    }
    &-child:last-child {
      margin-right: 0;
    }
    .active {
      pointer-events: none;
      span {
        font-weight: bold;
        color: #000000;
      }
      .img2 {
        display: block !important;
      }
    }
    &-child:nth-child(1):hover {
      span {
        font-weight: bold;
        color: #3bcd0d;
      }
      .childImg {
        background-image: url("../../assets/icon_phone2.png") !important;
      }
    }
    &-child:nth-child(2):hover {
      span {
        font-weight: bold;
        color: #3bcd0d;
      }
      .childImg {
        background-image: url("../../assets/icon_pad2.png") !important;
      }
    }
    &-child:nth-child(3):hover {
      span {
        font-weight: bold;
        color: #3bcd0d;
      }
      .childImg {
        background-image: url("../../assets/icon_TV2.png") !important;
      }
    }
  }

  .contain2 {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .bigTitle {
    width: 100%;
    height: 500px;
    background: #4967ff;
    &-contain {
      width: 1200px;
      margin: 0 auto;
      z-index: 2;
      position: relative;
      h3 {
        font-size: 56px;
        font-weight: bold;
        line-height: 95px;
        color: #ffffff;
        padding: 96px 0 16px 30px;
      }
      p {
        width: 564px;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 28px;
        color: #ffffff;
        opacity: 0.8;
        padding-left: 30px;
        box-sizing: border-box;
      }
      ul {
        margin-top: 39px;
        padding-left: 51px;
        li {
          font-size: 24px;
          font-weight: 500;
          line-height: 41px;
          color: #ffffff;
        }
      }
      .appBg {
        position: absolute;
        top: 110px;
        right: -139px;
        width: 900px;
        height: 643px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .appIntrodoce {
    width: 1200px;
    margin: 0 auto;
    height: 340px;
    &-left {
      display: inline-block;
      width: 142px;
      height: 142px;
      margin: 39px 0 0 4px;
      vertical-align: top;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-right {
      display: inline-block;
      width: 368px;
      margin: 66px 0 0 8px;
      vertical-align: top;
      h3 {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
        line-height: 28px;
      }
      p {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 27px;
        color: #5e6577;
        margin-top: 11px;
        @include textOF(3);
      }
    }
    &-item {
      display: inline-block;
      width: 571px;
      height: 106px;
      z-index: 3;
      margin: 34px 0 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .appOffice {
    width: 100%;
    background-color: #f4f7f9;
    padding-bottom: 98px;
    @include display(top, center);
    .officeContain {
      text-align: center;
      padding-top: 100px;
      h3 {
        display: inline-block;
        font-size: 38px;
        font-weight: bold;
        color: #000000;
        position: relative;
      }
      h3::before {
        position: absolute;
        top: 50%;
        left: -90px;
        display: block;
        content: "";
        background: url("../../assets/titleLeft.png") no-repeat;
        width: 74px;
        height: 3px;
        transform: translateY(50%);
      }
      h3::after {
        position: absolute;
        top: 50%;
        right: -90px;
        display: block;
        content: "";
        background: url("../../assets/titleRight.png") no-repeat;
        width: 74px;
        height: 3px;
        transform: translateY(50%);
      }
      p {
        // width: 732px;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #5e6577;
        text-align: center;
        margin: 68px auto 0;
        b {
          font-size: 40px;
          color: #41cf14;
        }
      }
    }
    .officeContain-img {
      width: 1130px;
      height: 510px;
      margin: 100px auto 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .contain {
    animation-duration: 500ms;
  }
}
</style>